import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(
    private keycloakService: KeycloakService
  ) {
  }

  public login(redirectUri?: string): Observable<void> {
    return from(this.keycloakService.login({ redirectUri }));
  }

  public logout(): Observable<void> {
    return from(this.keycloakService.logout(window.location.origin));
  }
}
