import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppTranslateLoader } from './app-translate.loader';
import { TranslateFakeLoader, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { ApiModule } from '../../../global-shared/shared/api/api.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderModule } from '../../../global-shared/shared/components/loader/loader.module';
import { AppState } from '../../../global-shared/state/app.state';
import { LanguageModule } from '../../../global-shared/shared/services/language/language.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializer } from '../../../global-shared/auth/auth.init';
import { AuthModule } from '../../../global-shared/auth/auth.module';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule,
    AuthModule,
    LoaderModule,
    LanguageModule,
    KeycloakAngularModule,
    NgxsModule.forRoot([AppState], {
      developmentMode: !environment.production
    }),
    NgxsResetPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: environment.production || environment.useTranslations
          ? AppTranslateLoader : TranslateFakeLoader
      },
      defaultLanguage: environment.language
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    }
  ]
})
export class AppModule {
}
