import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadUser, ResetUser, SetLoading } from './app.actions';
import { User } from '../shared/api/models/user';
import { ApiService } from '../shared/api/api.service';
import { mergeMap, tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { Role } from '../shared/api/models/enums/role.enum';
import { LanguageService } from '../shared/services/language/language.service';

export interface App {
  user?: User;
  loading: boolean;
}

@State<App>({
  name: 'AppState',
  defaults: { loading: false }
})
@Injectable()
export class AppState {
  constructor(
    private apiService: ApiService,
    private languageService: LanguageService
  ) {
  }

  @Selector()
  static user(state: App): User | undefined {
    return state.user;
  }

  @Selector()
  static role(state: App): Role | undefined {
    return state.user?.role;
  }

  @Selector()
  static loading(state: App): boolean {
    return state.loading;
  }

  @Action(ResetUser)
  private resetUser({ patchState }: StateContext<App>): Observable<any> {
    return of(EMPTY)
      .pipe(
        tap(() => patchState({ user: undefined })),
        mergeMap(() => this.languageService.setBrowserLang())
      );
  }

  @Action(LoadUser, { cancelUncompleted: true })
  private loadUser({ patchState }: StateContext<App>): Observable<any> {
    return this.apiService.auth.profile()
      .pipe(
        //TODO: Remove role setting after finishing user management service on backend
        tap(user => patchState({ user: { ...user, ...(user as any).profile } })),
        //TODO: Remove any after finishing user management service on backend
        tap((user: any) => this.languageService.setLang(user.profile.language))
      );
  }

  @Action(SetLoading)
  private setLoading({ patchState }: StateContext<App>, { payload }: SetLoading): void {
    patchState({ loading: payload.loading });
  }
}
