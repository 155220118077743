import { Expose, Transform, Type } from 'class-transformer';
import { Info } from 'luxon';
import { Model } from './query';

export interface Bill {
  id: number;
  year: number;
  month: number;
  filename: string;
  billAutoNumber: number;
  link: string;
  invoiceNumber: string;
}

export class BillModel extends Model {
  @Expose() id!: number;
  @Expose() year!: number;
  @Expose()
  @Transform(({ value }) => Info.months()[value - 1])
  month!: string;
  @Expose() filename!: string;
  @Expose() billAutoNumber!: number;
  @Expose() link!: string;
  @Expose() invoiceNumber!: string;
}

export interface GroupedBills {
  [year: number]: {
    [month: string]: BillModel[];
  }
}

export class GroupedBillsResponse extends Model {
  @Type(() => BillModel)
  @Expose({ name: 'data' })
  @Transform(({ value }) => value.reduce(
    (bills: GroupedBills, bill: BillModel) => {
      bills[bill.year] = bills[bill.year] || {};
      bills[bill.year][bill.month] = bills[bill.year][bill.month] || [];
      bills[bill.year][bill.month].push(bill);
      return bills;
    }, {})
  )
  bills!: GroupedBills;
}
