import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../loki-desktop/src/environments/environment';
import { from, Observable } from 'rxjs';
import { ResetPasswordDto } from './models/dtos/reset-password.dto';
import { ForgotPasswordDto } from './models/dtos/forgot-password.dto';
import { User } from './models/user';
import { KeycloakService } from 'keycloak-angular';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthDataService {
  private readonly url: string;

  constructor(
    private http: HttpClient,
    private kc: KeycloakService
  ) {
    this.url = `${environment.api.gatewayURL}/ums/public/api/v1`;
  }

  public forgotPassword(recovery: ForgotPasswordDto): Observable<void> {
    return this.http.post<void>(`${this.url}/password/send-token`, null, {
      params: { ...recovery }
    });
  }

  public resetPassword(credentials: ResetPasswordDto): Observable<void> {
    return this.http.put<void>(`${this.url}/password/recover`, credentials);
  }

  public profile(): Observable<User> {
    return from(this.kc.loadUserProfile())
      .pipe(
        map((profile: any) => JSON.parse(profile.attributes.userResources[0]).userId),
        switchMap(id => this.http.get<User>(`${this.url}/users/info?userId=${id}`))
      );
  }
}
