import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ApiService } from './api.service';
import { UsersDataService } from './users-data.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthDataService } from './auth-data.service';
import { CustomersDataService } from './customers-data.service';
import { StatisticDataService } from './statistic-data.service';
import { BillsDataService } from './bills-data.service';
import { NotificationDataService } from './notification-data.service';

@NgModule({
  providers: [
    ApiService,
    AuthDataService,
    UsersDataService,
    CustomersDataService,
    StatisticDataService,
    BillsDataService,
    NotificationDataService
  ],
  imports: [
    HttpClientModule
  ]
})
export class ApiModule {
  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import it in the root module only.');
    }
  }
}
