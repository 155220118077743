import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from '../../../global-shared/state/app.state';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../global-shared/shared/services/language/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @Select(AppState.loading) loading$!: Observable<boolean>;

  constructor(
    private languageService: LanguageService
  ) {
    this.languageService.setBrowserLang();
  }
}
