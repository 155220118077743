import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerListDto } from './models/dtos/customer-list.dto';

@Injectable()
export class CustomersDataService {
  private readonly url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = '/api/customers';
  }

  public list(query: any): Observable<CustomerListDto> {
    const params = { ...query.serialize() } as any;
    params.page--;

    return this.http.get<CustomerListDto>(this.url, { params })
      .pipe(
        tap(res => res.page++)
      );
  }
}
