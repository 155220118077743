import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Lang } from '../../api/models/enums/lang.enum';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageService {
  public get browserLang(): string {
    const lang: string = navigator.language || (navigator as any).userLanguage;
    return lang.split('-')[0];
  }

  constructor(
    private translate: TranslateService
  ) {
  }

  public setBrowserLang(): Observable<any> {
    return this.setLang(this.browserLang as Lang);
  }

  public setLang(lang: Lang): Observable<any> {
    return this.translate.use(lang.toLowerCase());
  }
}
