export class LoadUser {
  static type = '[App] Load User';
}

export class ResetUser {
  static type = '[App] Reset User';
}

export class SetLoading {
  static type = '[App] Set Loading';

  constructor(public payload: { loading: boolean }) {
  }
}
