import { Model } from '../query';
import { Exclude, Expose, Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { Mapper } from '../mapper';

export type EmailMessage = Omit<EmailMessageQuery, 'id' | 'lang' | 'operationId' | 'type'>

export interface MessageStatus {
  message: string;
}

export interface EmailStatus extends MessageStatus {
  id: string;
  operationId: string;
  title: string;
  receiver: string;
  recipient: string;
  sender: string;
  messageKind: 'Support' | 'DataDepartment' | 'MediaProduction' | 'AccountManagement' | 'BookKeeping';
  notificationStatus: NotificationStatus;
  notificationType: NotificationType;
  deleted: boolean;
  createdAt: string;
  modifiedAt: string;
}

export class EmailMessageQuery extends Model {
  @Expose() lang!: string;
  @Expose() receivers!: Receivers[];
  @Expose() subject?: string;
  @Expose() emailReplyTo?: string;
  @Expose() operationId?: string;
  @Expose() template?: string = '<html></html>';
  @Expose() type?: NotificationType = NotificationType.EMAIL;
}

export class NotificationMessageQuery extends EmailMessageQuery {
  @Expose() type?: NotificationType = NotificationType.EMAIL;

  @Exclude() lang!: string;
  @Exclude() subject?: string;
  @Exclude() emailReplyTo?: string;
  @Exclude() operationId?: string;
  @Exclude() template?: string = '<html></html>';

  @Expose() priority: number = 0;

  @Expose()
  @Transform(p => NotificationMessageQuery.reduce(p.value, r => r.email))
  public receivers!: Receivers[];

  @Expose()
  @Transform(p => NotificationMessageQuery.reduce(p.obj.receivers, r => r.attachments?.map(a =>
    ({ first: a.fileName, second: a.fileUrl }))))
  private attachments!: any;

  @Expose()
  @Transform(p => ({ subject: p.obj.subject, ...NotificationMessageQuery.reduce(p.obj.receivers, () => p.obj.template) }))
  private content: any;

  @Expose()
  @Transform(p => ({ source: p.obj.emailReplyTo }))
  private address!: { source: string };

  private static reduce(arr: Receivers[], prop: (r: Receivers) => any): any {
    return arr.reduce((p, c) => (p[c.email] = prop(c), p), {} as any);
  }
}

export interface Receivers {
  email: string;
  name: string;
  recipient: string;
  attachments?: Attachment[];
}

export interface Attachment {
  fileName: string;
  fileUrl: string;
}

export enum NotificationType {
  FAX = 'Fax',
  EMAIL = 'Email',
  PUSH = 'Push',
  SMS = 'Sms',
  MESSENGER = 'Messenger'
}

export enum NotificationStatus {
  DELIVERED = 'Delivered',
  SENT = 'Sent',
  FAILED = 'Failed'
}

export interface Push {
  date: DateTime;
  applicationId: string;
  title: string;
  text: string;
  username: string;
  locale: string;
}

export class PushRequestMapper extends Model {
  @Exclude() applicationId!: string;
  @Exclude() title!: string;
  @Exclude() text!: string;
  @Exclude() username!: string;
  @Exclude() locale!: string;

  @Expose()
  private type: NotificationType = NotificationType.PUSH;

  @Expose()
  private priority: number = 0;

  @Expose()
  @Transform(p => ({ username: p.obj.username }))
  private receivers!: Receivers[];

  @Expose()
  @Transform(p => ({
    applicationId: p.obj.applicationId,
    title: p.obj.title,
    text: p.obj.text,
    username: p.obj.username,
    locale: p.obj.locale
  }))
  private content: any;

  @Expose()
  @Transform(p => ({ source: p.obj.username }))
  private address!: { source: string };
}

export class PushResponseMapper extends Model {
  @Expose() applicationId!: string;
  @Expose() title!: string;
  @Expose() username!: string;
  @Expose({ name: 'message' }) text!: string;
  @Expose() @Transform(p => p.value.toLowerCase()) locale!: string;
  @Expose({ name: 'created' }) @Transform(Mapper.dateParse) date!: DateTime;
}
