export const environment = {
  production: false,
  language: 'en',
  useMocks: false,
  useTranslations: true,
  api: {
    shopURL: 'https://shop-dev.devteam.win',
    phpURL: 'https://php-integration.devteam.win',
    gatewayURL: 'https://api-gateway-ap-feature.devteam.win'
  },
  keycloak: {
    url: 'https://feat-auth.devteam.win/auth',
    clientId: 'loki',
    realm: 'test'
  }
};
