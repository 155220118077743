import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../loki-desktop/src/environments/environment';

export const initializer = (keycloak: KeycloakService): () => Promise<void> => () => new Promise(async (resolve, reject) => {
  try {
    await keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId
      },
      loadUserProfileAtStartUp: true,
      initOptions: {
        onLoad: 'check-sso',
      },
      bearerExcludedUrls: [],
    });
    resolve();
  } catch (error) {
    reject(error);
  }
});
