import { Injectable } from '@angular/core';
import { UsersDataService } from './users-data.service';
import { AuthDataService } from './auth-data.service';
import { CustomersDataService } from './customers-data.service';
import { StatisticDataService } from './statistic-data.service';
import { BillsDataService } from './bills-data.service';

@Injectable()
export class ApiService {
  constructor(
    public auth: AuthDataService,
    public users: UsersDataService,
    public customers: CustomersDataService,
    public stats: StatisticDataService,
    public bills: BillsDataService
  ) {
  }
}
