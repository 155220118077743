import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../global-shared/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    // todo: redirect to dashboard
    redirectTo: '1765/orders'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    data: { public: true },
    loadChildren: () => import('../../../global-shared/public/public.module').then(m => m.PublicModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
  },
  {
    path: '**',
    redirectTo: '1765/orders'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
